import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { isEmpty, isEqual } from 'lodash-es';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SelectItem } from '~app/shared/models/select-item.model';
import { ColorType } from '~app/shared/components/statistical-card/enums/color-type.enum';
import { SelectFilterConfig, FilterRibbonConfig } from './models/filter-ribbon-config.model';
import { SelectedFormValue } from './models/selected-form-value.model';
import { RibbonType } from '../grid-table/enums/ribbon-type.enum';

@Component({
  selector: 'tmc-filter-ribbon',
  templateUrl: './filter-ribbon.component.html',
  styleUrls: ['./filter-ribbon.component.scss']
})
export class FilterRibbonComponent implements OnInit, OnChanges, OnDestroy {
  @Input() config: FilterRibbonConfig;
  @Output() formValueChange = new EventEmitter<Record<string, any>>();
  @Output() collapseToggled = new EventEmitter<void>();

  public form: FormGroup;
  public selectedFormValues: SelectedFormValue[] = [];
  public collapsed = true;
  public ribbonType = RibbonType;
  public readonly filterDivisionColors = [
    ColorType.pinkLight,
    ColorType.blueLight,
    ColorType.orangeLight,
    ColorType.yellowLight,
    ColorType.greenLight
  ];

  public defaultMinDate: Date = new Date(new Date().getFullYear(), new Date().getMonth() + 1, -14);
  public defaultMaxDate: Date = new Date(new Date().getFullYear(), new Date().getMonth() + 1);

  private destroyed$ = new Subject<void>();
  private formValueChangeSubscription: Subscription;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    if (this.config) {
      this.buildForm();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config && !changes.config.firstChange) {
      this.applyChangedConfig(changes.config);
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  toggleContent() {
    this.collapsed = !this.collapsed;
    setTimeout(() => {
      this.collapseToggled.emit();
    }, 10);
  }

  removeSelectedFormValue(event: Event, formControlName: string, itemToRemove: SelectItem<any>) {
    event.stopPropagation();

    const controlValue = this.form.value[formControlName];
    let newValue = null;

    if (controlValue.length) {
      newValue = (controlValue as SelectItem<any>[]).filter(
        item => !(item.label === itemToRemove.label && item.value === itemToRemove.value)
      );
    }

    this.form.patchValue({
      [formControlName]: newValue
    });
  }

  private applyChangedConfig(changedConfig: SimpleChange) {
    const shouldRebuild = this.hasFilterChange(changedConfig.previousValue.filters, changedConfig.currentValue.filters);

    if (shouldRebuild) {
      this.buildForm();
    } else {
      this.setFormValues();
    }
  }

  private buildForm() {
    this.form = this.formBuilder.group(this.getFormGroup());
    this.setFormValues();
    this.subscribeToFormChanges();
  }

  private getFormGroup() {
    return this.config.filters.reduce((res, val) => {
      res[val.formControlName] = null;

      return res;
    }, {} as Record<string, any>);
  }

  private setFormValues() {
    const { initialFormValue } = this.config;

    if (initialFormValue && !isEmpty(initialFormValue)) {
      this.form.patchValue(initialFormValue, { emitEvent: false });
      this.setSelectedFormValues(initialFormValue);
    }
  }

  private setSelectedFormValues(formValue: any) {
    this.selectedFormValues = this.config.filters.reduce((map, val) => {
      const { label, formControlName } = val;
      const controlValue = formValue[formControlName];

      if (this.isControlValueValid(controlValue)) {
        map.push({
          label,
          formControlName,
          value: controlValue.length ? controlValue : [controlValue]
        });
      }

      return map;
    }, [] as SelectedFormValue[]);
  }

  private isControlValueValid(controlValue?: any): boolean {
    return controlValue && (controlValue.length > 0 || controlValue.value);
  }

  private subscribeToFormChanges() {
    if (this.formValueChangeSubscription) {
      this.formValueChangeSubscription.unsubscribe();
    }

    this.formValueChangeSubscription = this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.setSelectedFormValues(value);
      this.formValueChange.emit(value);
    });
  }

  private hasFilterChange(previousFilters: SelectFilterConfig[], currentFilters: SelectFilterConfig[]): boolean {
    return !isEqual(
      previousFilters.map(f => f.formControlName),
      currentFilters.map(f => f.formControlName)
    );
  }
}

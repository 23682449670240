import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { TableColumn } from '@swimlane/ngx-datatable';
import { get } from 'lodash-es';

import { ColumnSort } from './enums/column-sort.model';

@Component({
  selector: 'tmc-grid-table',
  templateUrl: './grid-table.component.html',
  styleUrls: ['./grid-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridTableComponent implements OnChanges {
  @ViewChild('dateTimeCellTemplate', { static: true }) dateTimeCellTemplate: TemplateRef<any>;
  @ViewChild('iconCellTemplate', { static: true }) iconCellTemplate: TemplateRef<any>;

  @Input() rows: any[] = [];
  @Input() columns: TableColumn[] = [];
  @Input() limit: number | undefined = undefined;
  @Input() offset: number = 0;
  @Input() sorts: ColumnSort[] = [];
  @Input() loadingIndicator = false;
  @Input() scrollbarH = true;
  @Input() scrollbarV = false;
  @Input() height = ''
  @Input() externalPaging = false;
  @Input() externalSorting = false;
  @Input() virtualization = true;
  @Input() rowHeight: 'auto' | number = 'auto';
  @Input() count = 0;
  @Output() pageChange = new EventEmitter<number>();

  public columnMode = ColumnMode;

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.height) {
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent('resize'));
      }, 10);
    }

    if (changes.columns) {
      this.columns = this.setColumnTemplates(changes.columns.currentValue);
    }
  }

  public setPage(pageInfo: any) {
    this.pageChange.emit(pageInfo.offset);
  }

  private setColumnTemplates(columns: TableColumn[]): TableColumn[] {
    columns.forEach(col => {
      col.draggable = false;

      if (typeof col.cellTemplate === 'string') {
        col.cellTemplate = get(this, col.cellTemplate);
      }

      if (typeof col.headerTemplate === 'string') {
        col.headerTemplate = get(this, col.headerTemplate);
      }
    });

    return columns;
  }
}

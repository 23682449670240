import { Component, Input } from '@angular/core';

import { CustomControl } from '~app/shared/services/base/custom-control';

@Component({
  selector: 'tmc-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent extends CustomControl  {
  @Input() label = '';
}
